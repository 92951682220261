import { Divider, Typography } from 'antd'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/Layout'

const docTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout selectedMenuKey={frontmatter.path.substring(1)}>
      <div className="container my3">
        <Typography.Title level={3} className="mb2">
          {frontmatter.title}
        </Typography.Title>
        <span className="italic">
          <FormattedMessage
            id="tplt.doctemplate.updated"
            values={{ date: frontmatter.date }}
          />
        </span>
        <Divider />
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

docTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`

export default docTemplate
